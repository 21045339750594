<template>
  <div>
    <el-form :inline="true">
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="balancelist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#" width="80px"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column prop="contract" :label="$t('yh.bibi')"></el-table-column>
      <el-table-column
        prop="contract_freeze"
        :label="$t('yh.bbdj')"
      ></el-table-column>
      <el-table-column
        prop="legal_tender"
        :label="$t('yh.fabi')"
      ></el-table-column>
      <el-table-column
        prop="legal_tender_freeze"
        :label="$t('yh.fbdj')"
      ></el-table-column>
      <el-table-column prop="product_name" :label="$t('yh.cplx')">
      </el-table-column>
      <el-table-column prop="total" :label="$t('yh.zongji')"></el-table-column>
      <el-table-column :label="$t('caozuo')">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('yh.ckxfjl')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="show(row.user_id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 消费记录 -->
    <el-drawer
      :visible="drawershow"
      direction="rtl"
      size="90%"
      :destroy-on-close="true"
      :before-close="close"
    >
      <template slot="title">
        <h3 style="text-align:center">
          <span style="float:left;">{{ $t('yh.yonghu') }}:{{ username }}</span
          >{{ $t('yh.xfjl') }}
        </h3>
      </template>
      <el-table :data="assetslog" border style="width: 100%">
        <el-table-column prop="id" label="#"> </el-table-column>
        <el-table-column prop="product_type" :label="$t('yh.cplx')">
        </el-table-column>
        <el-table-column prop="usable" :label="$t('yh.bibi')">
        </el-table-column>
        <el-table-column prop="freeze" :label="$t('yh.bbdj')">
        </el-table-column>
        <el-table-column prop="legal_tender" :label="$t('yh.fabi')">
        </el-table-column>
        <el-table-column prop="legal_tender_freeze" :label="$t('yh.fbdj')">
        </el-table-column>
        <el-table-column prop="contract" :label="$t('yh.chuangxin')">
        </el-table-column>
        <el-table-column prop="contract_freeze" :label="$t('yh.cxdj')">
        </el-table-column>
        <el-table-column prop="defi" label="DeFi"> </el-table-column>
        <el-table-column prop="defi_freeze" :label="$t('yh.dfdj')">
        </el-table-column>
        <el-table-column prop="total" :label="$t('yh.zongji')">
        </el-table-column>
        <el-table-column prop="reason" :label="$t('yh.czyy')">
        </el-table-column>
        <el-table-column prop="type" :label="$t('yh.czlx')"> </el-table-column>
        <el-table-column prop="orderno" :label="$t('yh.gldh')">
        </el-table-column>
        <el-table-column prop="actiontime" :label="$t('yh.czsj')">
          <template slot-scope="{ row }">
            {{ row.actiontime | dateformat }}
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryinfo: {
        query: '',
        page: 1
      },
      balancelist: [],
      total: 0,
      loading: true,
      // 消费记录
      drawershow: false,
      assetQuery: {
        query: '',
        page: 1,
        uid: ''
      },
      assetslog: [],
      assetLoading: false,
      assetTotal: 0,
      username: ''
    }
  },
  created() {
    // 请求用户列表数据
    this.getlist()
  },
  methods: {
    // 获取用户列表
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/user/balancelist', {
        params: this.queryinfo
      })
      if (data) {
        if (data.code !== 200) {
          this.$message.error(this.getlang(data.msg))
        } else {
          this.balancelist = data.data.list
          this.total = parseInt(data.data.count)
        }
      }
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 查看消费记录
    async show(uid) {
      this.assetQuery.uid = uid
      this.drawershow = true
      this.getassetlog()
    },
    // 获取消费记录
    async getassetlog() {
      this.assetLoading = true
      const { data } = await this.$http.get('/admin/user/assetloglist', {
        params: this.assetQuery
      })
      if (data) {
        if (data.code === 200) {
          this.assetslog = data.data.list
          this.assetTotal = data.data.count
          this.username = data.data.username
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
    },
    // 消费记录分页事件
    assetpage(val) {
      this.assetQuery.page = val
      this.getassetlog()
    },
    // 关闭消费记录
    close() {
      this.drawershow = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
.iconfont {
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
</style>
